import { useQuery } from '@apollo/client';
import { Avatar, Card, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMedia } from 'react-use';
import { BREAKPOINTS, LOGS_TABS_KEYS, ROUTES } from '../../common/constants';
import TodoList from './components/Approval/TodoList';
import DashboardList from './components/DashboardList';
import { GET_AGENCY_EQC_APPROVAL_LOGS_COUNT } from './graphql/Queries';

const initialTodoFilter = {
  skip: 0,
  limit: 10,
  sortBy: { field: 'updatedAt', order: 'DESC' }
};
const Dashboard = () => {
  const { TabPane } = Tabs;
  const { tab } = useParams();
  const history = useHistory();
  const [activeKey, setActiveKey] = useState(LOGS_TABS_KEYS.ISSUE);

  const { data } = useQuery(GET_AGENCY_EQC_APPROVAL_LOGS_COUNT, {
    variables: { filter: initialTodoFilter },
    fetchPolicy: 'network-only'
  });

  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  useEffect(() => {
    if (tab === LOGS_TABS_KEYS.ISSUE) {
      setActiveKey(LOGS_TABS_KEYS.ISSUE);
    } else if (tab === LOGS_TABS_KEYS.TODO) {
      setActiveKey(LOGS_TABS_KEYS.TODO);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="logs">
        <div className="header-text">To do</div>
        <Tabs
          activeKey={activeKey}
          destroyInactiveTabPane
          onChange={(key) => {
            setActiveKey(key);
            history.push(`${ROUTES.DASHBOARD}/${key}`);
          }}
        >
          <TabPane
            tab="Issues"
            key={LOGS_TABS_KEYS.ISSUE}
            className="details-tab"
          >
            <div className="activity">
              <Card className="project-module">
                <div className="project-header">
                  <div
                    id="add-search-projects"
                    className={`  ${
                      !isDesktopViewport
                        ? 'width-percent-100'
                        : 'd-flex align-center justify-end'
                    }`}
                  />
                </div>
                <DashboardList />
              </Card>
            </div>
          </TabPane>
          <TabPane
            tab={
              <>
                Pending Approvals
                {data?.getAgencyEqcApprovalLogs?.total > 0 && (
                  <Avatar className="todo-count" size={25}>
                    {data?.getAgencyEqcApprovalLogs?.total}
                  </Avatar>
                )}
              </>
            }
            key={LOGS_TABS_KEYS.TODO}
          >
            <div className="activity">
              <Card className="project-module">
                <div className="project-header">
                  <div
                    id="add-search-projects"
                    className={`  ${
                      !isDesktopViewport
                        ? 'width-percent-100'
                        : 'd-flex align-center justify-end'
                    }`}
                  />
                </div>
                <TodoList />
              </Card>
            </div>
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};

export default Dashboard;
