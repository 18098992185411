import { useLazyQuery } from '@apollo/client';
import { Col, Empty, Row, Tooltip } from 'antd';
import { forEach, map, nth, omit, slice } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import { InfoIconDark, TrofiInHand } from '../../../../assets/svg';
import {
  BREAKPOINTS,
  DEFAULTDATEFORMAT,
  DEFAULT_PAGE_SIZE,
  INS_STATUS_KEYS
} from '../../../../common/constants';
import CollapsibleFilterWrapper from '../../../../components/CollapsibleFilterWrapper';
import CommonCard from '../../../../components/CommonCard';
import CommonTable from '../../../../components/CommonTable';
import InfiniteScrollHandler from '../../../../components/InfiniteScrollHandler';
import Portal from '../../../../components/Portal';
import SearchComponent from '../../../../components/SearchComponent';
import { GET_AGENCY_EQC_APPROVAL_LOGS } from '../../graphql/Queries';
import Filters from '../Filters';

const TodoList = () => {
  const history = useHistory();
  const navFilter = history?.location?.state?.navFilter;
  const navPagination = history?.location?.state?.navPagination;
  const tenantRecord = history?.location?.state?.tenantRecord || [];
  const projectRecord = history?.location?.state?.projectRecord || [];
  const initialProjectFilter = {
    skip: 0,
    limit: 10,
    sortBy: { field: 'updatedAt', order: 'DESC' }
  };
  const initialPaginationValue = {
    total: 0,
    current: 1
  };
  const [paginationProp, setPaginationProp] = useState(
    navPagination || initialPaginationValue
  );
  const [projectFilter, setProjectFilter] = useState(
    navFilter || initialProjectFilter
  );
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [hasMore, setHasMore] = useState(true);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});
  const [instructionListData, setInstructionListData] = useState([]);
  const [tenantId, setTenantId] = useState(projectFilter?.tenantId);
  const [projectId, setProjectId] = useState(projectFilter?.projectId);
  const [selectedTenantRecord, setSelectedTenantRecord] = useState(
    tenantRecord
  );
  const [selectedProjectRecord, setSelectedProjectRecord] = useState(
    projectRecord
  );

  const [fetchInstructionList, { loading, called }] = useLazyQuery(
    GET_AGENCY_EQC_APPROVAL_LOGS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const data = res?.getAgencyEqcApprovalLogs?.data || [];
        const pagination = {
          ...paginationProp,
          defaultPageSize: DEFAULT_PAGE_SIZE,
          total: res?.getAgencyEqcApprovalLogs?.total
        };
        if (scrollFlag) {
          const datalist = [...instructionListData, ...data];
          setInstructionListData(datalist);
          setScrollFlag(false);
        } else {
          const datalist = [...data];
          setInstructionListData(datalist);
        }
        setHasMore(!!data?.length);
        setPaginationProp(pagination);
      },
      onError() {
        if (isDesktopViewport) {
          setInstructionListData([]);
        }
      }
    }
  );
  useEffect(() => {
    fetchInstructionList({ variables: { filter: projectFilter } });
    if (history?.location?.state) {
      // eslint-disable-next-line no-undef
      window.history.replaceState({}, document.title);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (called) {
      let newFilter = {
        ...projectFilter,
        projectId,
        tenantId,
        skip: 0
      };
      newFilter = omit(newFilter, 'isOverdue');
      setPaginationProp(initialPaginationValue);
      setProjectFilter(newFilter);
      fetchInstructionList({ variables: { filter: { ...newFilter } } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, tenantId]);

  const getStageNameAndStatus = (eqcStageHistory) => {
    const { name } = eqcStageHistory;
    return (
      <div>
        <span>{name || '-'}</span>
      </div>
    );
  };
  const getApproverName = (values, record) => {
    const fetchName = [];
    const { eqcStageApprovals } = record?.eqcStage;
    forEach(eqcStageApprovals, (approver) => {
      const {
        externalApproverName: extApprName,
        internalApproverName: intApprName
      } = approver;
      if (intApprName || extApprName) {
        fetchName.push(intApprName || extApprName);
      }
    });
    const selectedName = nth(fetchName, 0);
    if (fetchName?.length === 1) {
      return (
        <Tooltip title={selectedName} placement="right">
          {selectedName}
        </Tooltip>
      );
    }
    if (fetchName?.length > 1) {
      return (
        <Tooltip
          title={slice(fetchName, 1, fetchName?.length).join(', ')}
          placement="right"
        >
          {`${selectedName} and +${fetchName?.length - 1}`}
        </Tooltip>
      );
    }
    return '-';
  };

  const columns = [
    {
      title: '#',
      key: 'id',
      render: (text, record, index) => {
        return (
          <div className="d-flex align-center">
            <span>{projectFilter?.skip + index + 1}</span>
            <span className="ml-5 d-flex align-center">
              {record?.status === INS_STATUS_KEYS.NOTICE && (
                <Tooltip title="Notice">
                  <InfoIconDark height="20px" width="20px" />
                </Tooltip>
              )}
            </span>
          </div>
        );
      }
    },
    {
      title: 'PROJECT NAME',
      dataIndex: 'projectName',
      key: 'projectName',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'projectName' && sortedInfo?.order,
      render: (text, record) => record?.eqc?.project?.name
    },
    {
      title: 'NAME',
      dataIndex: 'eqcName',
      key: 'eqcName',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'eqcName' && sortedInfo?.order,
      render: (text, record) => record?.eqc?.eqcName
    },
    {
      title: 'TYPE',
      dataIndex: 'projectEqcTypeName',
      key: 'projectEqcTypeName',
      sorter: true,
      sortOrder:
        sortedInfo?.columnKey === 'projectEqcTypeName' && sortedInfo?.order,
      render: (text, record) => record?.eqc?.projectEqcTypeName || '-'
    },
    {
      title: 'STAGE(Approval Pending)',
      key: 'eqcStageHistory',
      dataIndex: 'eqcStageHistory',
      sorter: true,
      sortOrder:
        sortedInfo?.columnKey === 'eqcStageHistory' && sortedInfo?.order,
      render: (text) => {
        return getStageNameAndStatus(text);
      }
    },
    {
      title: 'APPROVERS',
      key: 'approver',
      dataIndex: 'approver',
      render: (values, record) => getApproverName(values, record)
    },
    {
      title: 'REQUESTED BY',
      key: 'approvalSender',
      dataIndex: 'approvalSender',
      sorter: true,
      sortOrder:
        sortedInfo?.columnKey === 'approvalSender' && sortedInfo?.order,
      render: (text, record) => record?.approvalSender?.name || '-'
    },
    {
      title: 'UPDATED AT',
      key: 'updatedAt',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'updatedAt' && sortedInfo?.order,
      render: (createdAt, record) => {
        return record?.updatedAt
          ? moment(record?.updatedAt).format(DEFAULTDATEFORMAT)
          : '-';
      }
    },
    {
      title: 'APPROVAL LINK',
      key: 'approvalUrl',
      dataIndex: 'approvalUrl',
      render: (text) => (
        <a href={text} target="_blank" rel="noreferrer">
          Review
        </a>
      )
    }
  ];
  const onSearchChange = async (value) => {
    setPaginationProp(initialPaginationValue);
    setProjectFilter({ ...projectFilter, skip: 0, search: value });
    fetchInstructionList({
      variables: {
        filter: {
          ...projectFilter,
          skip: 0,
          search: value
        }
      }
    });
  };

  const handleRefetch = () => {
    fetchInstructionList({
      variables: {
        filter: {
          ...projectFilter,
          skip: instructionListData?.length
        }
      }
    });
  };
  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setPaginationProp({ ...paginationProp, ...pagination });
    setSortedInfo(sorter);
    if (sorter?.column) {
      setProjectFilter({
        ...projectFilter,
        skip: skip,
        limit: pagination.pageSize,
        sortBy: {
          field: sorter.columnKey,
          order: sorter.order === 'ascend' ? 'ASC' : 'DESC'
        }
      });
      fetchInstructionList({
        variables: {
          filter: {
            ...projectFilter,
            skip,
            limit: pagination.pageSize,
            sortBy: {
              field: sorter.columnKey,
              order: sorter.order === 'ascend' ? 'ASC' : 'DESC'
            }
          }
        }
      });
    } else {
      setProjectFilter({
        ...projectFilter,
        skip: skip,
        limit: pagination.pageSize,
        sortBy: { field: 'createdAt', order: 'DESC' }
      });

      fetchInstructionList({
        variables: {
          filter: {
            ...projectFilter,
            skip,
            limit: pagination.pageSize,
            sortBy: { field: 'createdAt', order: 'DESC' }
          }
        }
      });
    }
  };

  const EmptyState = (
    <Empty
      image={TrofiInHand}
      imageStyle={{
        height: 148
      }}
      description={
        <div>
          <div className="empty-text">You are up to date!</div>
          <div className="empty-text-secondary">
            Awesome! You can now explore other tabs to stay up to date.
          </div>
        </div>
      }
    />
  );

  return (
    <>
      <Portal portalId="add-search-projects">
        <CollapsibleFilterWrapper
          searchProps={{
            id: 'search-container-id ',
            className: 'search-component',
            getData: onSearchChange,
            defaultValue: navFilter?.search,
            name: 'issue & notice'
          }}
          className="width-percent-100"
        >
          <Row
            className="d-flex filter-search"
            justify={isDesktopViewport ? 'end' : 'start'}
            align="middle"
            wrap
            gutter={[8, 8]}
          >
            <Filters
              tenantFilter
              projectFilter
              tenantId={tenantId}
              setTenantId={setTenantId}
              projectId={projectId}
              setProjectId={setProjectId}
              selectedProjectRecord={selectedProjectRecord}
              selectedTenantRecord={selectedTenantRecord}
              setSelectedProjectRecord={setSelectedProjectRecord}
              setSelectedTenantRecord={setSelectedTenantRecord}
            />

            {isDesktopViewport && (
              <Col>
                <SearchComponent
                  id="search-container-id"
                  className="search-component"
                  getData={onSearchChange}
                  defaultValue={navFilter?.search}
                />
              </Col>
            )}
          </Row>
        </CollapsibleFilterWrapper>
      </Portal>
      <div className="pointer todo-list">
        {isDesktopViewport ? (
          <CommonTable
            rowClassName={(record) => {
              if (record?.isActive === false) return 'deactivated-color';
            }}
            columns={columns}
            data={instructionListData}
            onChange={handleTableChange}
            paginationConfig={paginationProp}
            loading={loading}
            rowKey={(obj) => obj?.id}
            locale={{ emptyText: EmptyState }}
          />
        ) : (
          <InfiniteScrollHandler
            scrollFlag={scrollFlag}
            refetchData={handleRefetch}
            setScrollFlag={setScrollFlag}
            hasMore={hasMore}
            dataLength={instructionListData?.length}
            skeletonRows={columns?.length - 3}
            loading={loading}
            emptyState={EmptyState}
          >
            {instructionListData?.length < 1
              ? EmptyState
              : map(instructionListData, (todo, index) => {
                  return (
                    <CommonCard key={todo?.id}>
                      <div className="common-card d-flex">
                        <div className="mr-5 fw-medium">
                          <div className="d-flex">
                            <span className="mr-5 d-flex">
                              {todo?.status === INS_STATUS_KEYS.NOTICE && (
                                <Tooltip title="Notice">
                                  <InfoIconDark height="16px" width="16px" />
                                </Tooltip>
                              )}
                            </span>
                            {index + 1}.
                          </div>
                        </div>
                        <div>
                          <div className="card-header fw-medium">
                            <span className="text-break">
                              {todo?.project?.name}
                            </span>
                          </div>
                          <div className="card-content text-secondary">
                            <br />
                            <div className="mb-15">
                              <span className="fw-medium mr-5">Name:</span>
                              {todo?.eqc?.eqcName}
                            </div>
                            <div className="mb-15">
                              <span className="fw-medium mr-5">Type:</span>
                              {todo?.eqc?.projectEqcTypeName || '-'}
                            </div>
                            <div className="d-flex mb-15">
                              <span className="fw-medium mr-5">
                                Stage(Approval Pending):
                              </span>
                              {getStageNameAndStatus(todo?.eqcStageHistory) ||
                                '-'}
                            </div>
                            <div className="mb-15">
                              <span className="fw-medium mr-5">Approver:</span>
                              {todo?.phoneNo}
                            </div>
                            <div className="mb-15">
                              <span className="fw-medium mr-5">
                                Requested by:
                              </span>
                              {todo?.approvalSender?.name || '-'}
                            </div>
                            <div className="mb-15">
                              <span className="fw-medium mr-5">
                                Updated at:
                              </span>
                              {todo?.updatedAt
                                ? moment(todo?.updatedAt).format(
                                    DEFAULTDATEFORMAT
                                  )
                                : '-'}
                            </div>
                            <div className="mb-15">
                              <span className="fw-medium mr-5">
                                Approval link:
                              </span>
                              <a
                                href={todo?.approvalUrl}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Review
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </CommonCard>
                  );
                })}
          </InfiniteScrollHandler>
        )}
      </div>
    </>
  );
};

export default TodoList;
