import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import Error404 from '../../Error404';
import Details from './components/details';
import ApproverSuccess from './components/details/ResponseSuccess';
import Dashboard from './Dashboard';

const DashboardWrapper = () => {
  return (
    <Switch>
      <Route exact path={`${ROUTES.DASHBOARD}/:tab`} component={Dashboard} />
      <Route exact path={ROUTES.DETAILS} component={Details} />
      <Route exact path={ROUTES.APPROVER_SUCCESS} component={ApproverSuccess} />
      <Route path="*" exact component={Error404} />
    </Switch>
  );
};

export default DashboardWrapper;
