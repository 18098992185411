import React, { useEffect } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { ROUTES } from '../common/constants';
import Error404 from '../Error404';
import DashboardWrapper from '../modules/dashboard';

const ContentRoutes = () => {
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    if (location.pathname === '/') {
      history.replace(`${ROUTES.DASHBOARD}/issue`);
    }
  });
  return (
    <>
      <Switch>
        <Route path={ROUTES.MAIN} component={DashboardWrapper} />
        <Route path="*" exact component={Error404} />
      </Switch>
    </>
  );
};

export default ContentRoutes;
