import { Button, Card, Col, Row, Space, Tag } from 'antd';
import moment from 'moment';
import React, { useContext } from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../../../AppContext';
import { Import } from '../../../../assets/svg';
import {
  BREAKPOINTS,
  DATETIMEWITHBRACKET,
  DEFAULTDATEFORMAT,
  GA_EVENT,
  GA_LABEL,
  INS_STATUS_CLASSNAME,
  INS_STATUS_KEYS,
  INS_STATUS_LABEL
} from '../../../../common/constants';
import { Event } from '../../../../common/trackEvents';

const InstructionSummary = ({ instructionData }) => {
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const { getToken } = useContext(AppContext);
  const renderDownloadButton = () => {
    const token = getToken();
    const url = `${process.env.REACT_APP_REPORT_SERVER_URL}/api/v2/report/contact-instruction/${instructionData?.id}?access_token=Bearer ${token}`;
    return (
      <Button
        shape="round"
        icon={<Import />}
        className={`report-button b-0 ${
          isDesktopViewport ? '' : 'b-0 width-percent-100 justify-center'
        }`}
        target="_blank"
        href={url}
        onClick={() => {
          Event(GA_EVENT.DOWNLOAD_ISSUE_REPORT, {
            label: GA_LABEL.DOWNLOAD_ISSUE_REPORT,
            // eslint-disable-next-line no-undef
            pathname: window?.location?.href,
            instruction_id: instructionData?.id
          });
        }}
      >
        Download Report
      </Button>
    );
  };

  return (
    <div className="instruction-tab-details">
      <Card className="instruction-tab-details-summary">
        <div className="d-flex align-center justify-between mb-20">
          <div className="d-flex align-center">
            <h2 className="mb-0">Summary</h2>
            <Tag
              className={`${
                INS_STATUS_CLASSNAME[instructionData?.status]
              } ml-10`}
            >
              {INS_STATUS_LABEL[instructionData?.status]}
            </Tag>
          </div>
          {isDesktopViewport && renderDownloadButton()}
        </div>
        <Row className="fw-medium mb-25">
          <Col>
            <Space size={12}>
              <Space size={10}>
                <div>Organization:</div>
              </Space>
              <div className="text-secondary">
                {instructionData?.tenant?.organizationName || '-'}
              </div>
            </Space>
          </Col>
        </Row>
        <Row
          gutter={isDesktopViewport ? [75, 40] : [40, 30]}
          className="fw-medium mb-20"
        >
          <Col span={isDesktopViewport ? 6 : 24}>
            <Space direction="vertical" size={12}>
              <Space size={10}>
                <div className="text-secondary">Name</div>
              </Space>
              <div>{instructionData?.name || '-'}</div>
            </Space>
          </Col>
          <Col span={isDesktopViewport ? 6 : 24}>
            <Space direction="vertical" size={12}>
              <div className="text-secondary">Type</div>
              <div>{instructionData?.type || '-'}</div>
            </Space>
          </Col>
          <Col span={isDesktopViewport ? 6 : 24}>
            <Space direction="vertical" size={12}>
              <div className="text-secondary">Agency</div>
              <div>{instructionData?.projectAgency?.agency?.name || '-'}</div>
            </Space>
          </Col>
          <Col span={isDesktopViewport ? 6 : 24}>
            <Space direction="vertical" size={12}>
              <div className="text-secondary">Due Date</div>
              <div>
                {instructionData?.reminderDate
                  ? moment(instructionData?.reminderDate).format(
                      DEFAULTDATEFORMAT
                    )
                  : '-'}
              </div>
            </Space>
          </Col>
          <Col span={isDesktopViewport ? 6 : 24}>
            <Space direction="vertical" size={12}>
              <div className="text-secondary">Raised By</div>
              <div>{instructionData?.creator?.name || '-'}</div>
              <div>
                {instructionData?.createdOn &&
                  moment(instructionData?.createdOn).format(
                    DATETIMEWITHBRACKET
                  )}
              </div>
            </Space>
          </Col>
          {instructionData?.status !== INS_STATUS_KEYS.NOTICE && (
            <>
              <Col span={isDesktopViewport ? 6 : 24}>
                <Space direction="vertical" size={12}>
                  <div className="text-secondary">Responded By</div>
                  <div>{instructionData?.responder?.name || '-'}</div>
                  <div>
                    {instructionData?.respondedOn &&
                      moment(instructionData?.respondedOn).format(
                        DATETIMEWITHBRACKET
                      )}
                  </div>
                </Space>
              </Col>
              <Col span={isDesktopViewport ? 6 : 24}>
                <Space direction="vertical" size={12}>
                  <div className="text-secondary">Closed By</div>
                  <div>{instructionData?.closer?.name || '-'}</div>
                  <div>
                    {instructionData?.closedOn &&
                      moment(instructionData?.closedOn).format(
                        DATETIMEWITHBRACKET
                      )}
                  </div>
                </Space>
              </Col>
              <Col span={isDesktopViewport ? 6 : 24}>
                <Space direction="vertical" size={12}>
                  <div className="text-secondary">Revision</div>
                  <div>
                    {instructionData?.instructionIssueHistories?.[0]
                      ?.revision || '-'}
                  </div>
                </Space>
              </Col>
            </>
          )}
        </Row>
        {!isDesktopViewport && renderDownloadButton()}
      </Card>
    </div>
  );
};

export default InstructionSummary;
