import { useLazyQuery, useQuery } from '@apollo/client';
import { Checkbox, Col, Row, Tag, Tooltip } from 'antd';
import { includes, keys, map, omit } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import { InfoIconDark, Timer } from '../../../assets/svg';
import {
  BREAKPOINTS,
  DEFAULTDATEFORMAT,
  DEFAULT_PAGE_SIZE,
  INS_STATUS_CLASSNAME,
  INS_STATUS_KEYS,
  INS_STATUS_LABEL,
  ROUTES
} from '../../../common/constants';
import CollapsibleFilterWrapper from '../../../components/CollapsibleFilterWrapper';
import CommonCard from '../../../components/CommonCard';
import CommonSelect from '../../../components/CommonSelect';
import CommonTable from '../../../components/CommonTable';
import InfiniteScrollHandler from '../../../components/InfiniteScrollHandler';
import Portal from '../../../components/Portal';
import SearchComponent from '../../../components/SearchComponent';
import { GET_INSTRUCTION_LIST, TYPE_DROPDOWN } from '../graphql/Queries';
import Filters from './Filters';

const { Option } = CommonSelect;

const DashboardList = () => {
  const history = useHistory();
  const navFilter = history?.location?.state?.navFilter;
  const navPagination = history?.location?.state?.navPagination;
  const tenantRecord = history?.location?.state?.tenantRecord || [];
  const projectRecord = history?.location?.state?.projectRecord || [];
  const initialProjectFilter = {
    skip: 0,
    limit: 10,
    sortBy: { field: 'createdAt', order: 'DESC' }
  };
  const initialPaginationValue = {
    total: 0,
    current: 1
  };
  const [paginationProp, setPaginationProp] = useState(
    navPagination || initialPaginationValue
  );
  const [projectFilter, setProjectFilter] = useState(
    navFilter || initialProjectFilter
  );
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [hasMore, setHasMore] = useState(true);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});
  const [instructionListData, setInstructionListData] = useState([]);
  const [tenantId, setTenantId] = useState(projectFilter?.tenantId);
  const [status, setStatus] = useState(projectFilter?.status);
  const [type, setType] = useState(projectFilter?.type);
  const [projectId, setProjectId] = useState(projectFilter?.projectId);
  const [isOverdue, setIsOverdue] = useState(projectFilter?.isOverdue);
  const [selectedTenantRecord, setSelectedTenantRecord] = useState(
    tenantRecord
  );
  const [selectedProjectRecord, setSelectedProjectRecord] = useState(
    projectRecord
  );
  const { data: typeData, loading: typeLoading } = useQuery(TYPE_DROPDOWN, {
    fetchPolicy: 'network-only'
  });
  const [fetchInstructionList, { loading, called }] = useLazyQuery(
    GET_INSTRUCTION_LIST,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const data = res?.contactInstructionList?.data || [];
        const pagination = {
          ...paginationProp,
          defaultPageSize: DEFAULT_PAGE_SIZE,
          total: res?.contactInstructionList?.total
        };
        if (scrollFlag) {
          const datalist = [...instructionListData, ...data];
          setInstructionListData(datalist);
          setScrollFlag(false);
        } else {
          const datalist = [...data];
          setInstructionListData(datalist);
        }
        setHasMore(!!data?.length);
        setPaginationProp(pagination);
      },
      onError() {
        if (isDesktopViewport) {
          setInstructionListData([]);
        }
      }
    }
  );
  useEffect(() => {
    fetchInstructionList({ variables: { filter: projectFilter } });
    if (history?.location?.state) {
      // eslint-disable-next-line no-undef
      window.history.replaceState({}, document.title);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (called) {
      let newFilter = {
        ...projectFilter,
        status,
        type,
        projectId,
        tenantId,
        skip: 0
      };
      if (isOverdue) {
        newFilter.isOverdue = isOverdue;
      } else {
        newFilter = omit(newFilter, 'isOverdue');
      }
      setPaginationProp(initialPaginationValue);
      setProjectFilter(newFilter);
      fetchInstructionList({ variables: { filter: { ...newFilter } } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantId, status, projectId, type, isOverdue]);
  const getStatus = (record) => {
    return (
      <Tag className={INS_STATUS_CLASSNAME[record?.status]}>
        {INS_STATUS_LABEL[record?.status]}
      </Tag>
    );
  };
  const getDueDate = (record) => {
    const isOverDue = record?.isOverdue;
    return record?.isNotice || !record?.reminderDate ? (
      '-'
    ) : (
      <div className={`d-flex align-center ${isOverDue ? 'text-danger' : ''}`}>
        <div>{moment(record?.reminderDate).format(DEFAULTDATEFORMAT)}</div>
        {isOverDue && (
          <Tooltip title="Overdue">
            <div className="ml-5 d-flex align-center">
              <Timer height="18" width="18" />
            </div>
          </Tooltip>
        )}
      </div>
    );
  };
  const getUpdatedBy = (record) => {
    if (record?.status === INS_STATUS_KEYS.RESPONDED) {
      return record?.responder?.name || '-';
    }
    return record?.modifier?.name || '-';
  };
  const getTypeName = (record) => {
    if (
      !includes(typeData?.instructionTypeList, record?.type) ||
      record?.type === 'Other'
    ) {
      return `Other - ${record?.type}`;
    }
    return record?.type || '-';
  };
  const columns = [
    {
      title: '#',
      key: 'id',
      render: (text, record, index) => {
        return (
          <div className="d-flex align-center">
            <span>{projectFilter?.skip + index + 1}</span>
            <span className="ml-5 d-flex align-center">
              {record?.status === INS_STATUS_KEYS.NOTICE && (
                <Tooltip title="Notice">
                  <InfoIconDark height="20px" width="20px" />
                </Tooltip>
              )}
            </span>
          </div>
        );
      }
    },
    {
      title: 'PROJECT NAME',
      dataIndex: 'project',
      key: 'project',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'project' && sortedInfo?.order,
      render: (text, record) => record?.project?.name
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order
    },

    {
      title: 'TYPE',
      dataIndex: 'type',
      key: 'type',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'type' && sortedInfo?.order,
      render: (text, record) => getTypeName(record)
    },
    {
      title: 'STATUS',
      key: 'status',
      render: (users, record) => getStatus(record)
    },
    {
      title: 'DUE DATE',
      key: 'reminderDate',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'reminderDate' && sortedInfo?.order,
      render: (dueDate, record) => getDueDate(record)
    },
    {
      title: 'UPDATED BY',
      key: 'updatedBy',
      dataIndex: 'updatedBy',
      render: (text, record) => getUpdatedBy(record)
    },
    {
      title: 'RAISED DATE',
      key: 'createdAt',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'createdAt' && sortedInfo?.order,
      render: (createdAt, record) => {
        return record?.createdAt
          ? moment(record?.createdAt).format(DEFAULTDATEFORMAT)
          : '-';
      }
    },
    {
      title: 'ORGANIZATION',
      key: 'tenant',
      dataIndex: 'tenant',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'tenant' && sortedInfo?.order,
      render: (text, record) => record?.tenant?.organizationName
    }
  ];
  const onSearchChange = async (value) => {
    setPaginationProp(initialPaginationValue);
    setProjectFilter({ ...projectFilter, skip: 0, search: value });
    fetchInstructionList({
      variables: {
        filter: {
          ...projectFilter,
          skip: 0,
          search: value
        }
      }
    });
  };
  const handleRowClick = (record) => {
    if (record?.id) {
      history.push(`${ROUTES.INSTRUCTION}/${record?.id}`, {
        navFilter: projectFilter,
        navPagination: paginationProp,
        tenantRecord: selectedTenantRecord,
        projectRecord: selectedProjectRecord
      });
    }
  };

  const handleRefetch = () => {
    fetchInstructionList({
      variables: {
        filter: {
          ...projectFilter,
          skip: instructionListData?.length
        }
      }
    });
  };
  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setPaginationProp({ ...paginationProp, ...pagination });
    setSortedInfo(sorter);
    if (sorter?.column) {
      setProjectFilter({
        ...projectFilter,
        skip: skip,
        limit: pagination.pageSize,
        sortBy: {
          field: sorter.columnKey,
          order: sorter.order === 'ascend' ? 'ASC' : 'DESC'
        }
      });
      fetchInstructionList({
        variables: {
          filter: {
            ...projectFilter,
            skip,
            limit: pagination.pageSize,
            sortBy: {
              field: sorter.columnKey,
              order: sorter.order === 'ascend' ? 'ASC' : 'DESC'
            }
          }
        }
      });
    } else {
      setProjectFilter({
        ...projectFilter,
        skip: skip,
        limit: pagination.pageSize,
        sortBy: { field: 'createdAt', order: 'DESC' }
      });

      fetchInstructionList({
        variables: {
          filter: {
            ...projectFilter,
            skip,
            limit: pagination.pageSize,
            sortBy: { field: 'createdAt', order: 'DESC' }
          }
        }
      });
    }
  };

  return (
    <>
      <Portal portalId="add-search-projects">
        <CollapsibleFilterWrapper
          searchProps={{
            id: 'search-container-id ',
            className: 'search-component',
            getData: onSearchChange,
            defaultValue: navFilter?.search,
            name: 'issue & notice'
          }}
        >
          <Row
            className="d-flex filter-search"
            justify={isDesktopViewport ? 'end' : 'start'}
            align="middle"
            wrap
            gutter={[8, 8]}
          >
            <Col span={!isDesktopViewport && 24}>
              <Checkbox
                onChange={(event) => setIsOverdue(event?.target?.checked)}
                checked={isOverdue}
              >
                Overdue
              </Checkbox>
            </Col>
            <Filters
              tenantFilter
              projectFilter
              tenantId={tenantId}
              setTenantId={setTenantId}
              projectId={projectId}
              setProjectId={setProjectId}
              selectedProjectRecord={selectedProjectRecord}
              selectedTenantRecord={selectedTenantRecord}
              setSelectedProjectRecord={setSelectedProjectRecord}
              setSelectedTenantRecord={setSelectedTenantRecord}
            />
            <Col span={!isDesktopViewport && 12}>
              <CommonSelect
                placeholder="Type"
                className={
                  isDesktopViewport ? 'width-200' : 'width-percent-100'
                }
                allowClear
                loading={typeLoading}
                value={type}
                onChange={(value) => {
                  setType(value);
                }}
              >
                {map(typeData?.instructionTypeList, (item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </CommonSelect>
            </Col>
            <Col span={!isDesktopViewport && 12}>
              <CommonSelect
                placeholder="Status"
                className={
                  isDesktopViewport ? 'width-200' : 'width-percent-100'
                }
                allowClear
                value={status}
                onChange={(value) => setStatus(value)}
              >
                {map(keys(INS_STATUS_KEYS), (item) => (
                  <Option key={item} value={item}>
                    {INS_STATUS_LABEL[item]}
                  </Option>
                ))}
              </CommonSelect>
            </Col>
            {isDesktopViewport && (
              <Col>
                <SearchComponent
                  id="search-container-id "
                  className="search-component"
                  getData={onSearchChange}
                  defaultValue={navFilter?.search}
                  name="issue & notice"
                />
              </Col>
            )}
          </Row>
        </CollapsibleFilterWrapper>
      </Portal>
      <div className="pointer ">
        {isDesktopViewport ? (
          <CommonTable
            rowClassName={(record) => {
              if (record?.isActive === false) return 'deactivated-color';
            }}
            columns={columns}
            data={instructionListData}
            onChange={handleTableChange}
            paginationConfig={paginationProp}
            loading={loading}
            rowKey={(obj) => obj?.id}
            onRow={(record) => {
              return {
                onClick: () => handleRowClick(record)
              };
            }}
          />
        ) : (
          <InfiniteScrollHandler
            scrollFlag={scrollFlag}
            refetchData={handleRefetch}
            setScrollFlag={setScrollFlag}
            hasMore={hasMore}
            dataLength={instructionListData?.length}
            skeletonRows={columns?.length - 3}
            loading={loading}
          >
            {map(instructionListData, (record, index) => {
              return (
                <CommonCard
                  key={record?.id}
                  onClick={() => handleRowClick(record)}
                >
                  <div className="common-card d-flex">
                    <div className="mr-5 fw-medium">
                      <div className="d-flex">
                        <span className="mr-5 d-flex">
                          {record?.status === INS_STATUS_KEYS.NOTICE && (
                            <Tooltip title="Notice">
                              <InfoIconDark height="16px" width="16px" />
                            </Tooltip>
                          )}
                        </span>
                        {index + 1}.
                      </div>
                    </div>
                    <div>
                      <div className="card-header fw-medium">
                        <span className="text-break">{record?.name}</span>
                      </div>
                      <div className="card-content text-secondary">
                        <br />
                        <div className="mb-15">
                          <span className="fw-medium mr-5">Project Name:</span>
                          <span className="text-break">
                            {record?.project?.name}
                          </span>
                        </div>
                        <div className="mb-15">
                          <span className="fw-medium mr-5">Type:</span>
                          <span className="text-break">
                            {getTypeName(record)}
                          </span>
                        </div>
                        <div className="mb-15">
                          <span className="fw-medium mr-5">Status:</span>
                          {getStatus(record)}
                        </div>
                        <div className="mb-15 d-flex align-center">
                          <span className="fw-medium mr-5">Due Date:</span>
                          {getDueDate(record)}
                        </div>
                        <div className="mb-15">
                          <span className="fw-medium mr-5">Updated By:</span>
                          {getUpdatedBy(record)}
                        </div>
                        <div>
                          <span className="fw-medium mr-5">Raised Date:</span>
                          {moment(`${record?.createdAt}`).format(
                            DEFAULTDATEFORMAT
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </CommonCard>
              );
            })}
          </InfiniteScrollHandler>
        )}
      </div>
    </>
  );
};

export default DashboardList;
