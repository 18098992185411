import { Layout } from 'antd';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { LoginLogo } from '../../../assets/svg/login';
import { ROUTES } from '../../../common/constants';
import UserProfile from './UserProfile';

const { Header } = Layout;

const AppHeader = () => {
  const { getCurrentUser } = useContext(AppContext);
  const user = getCurrentUser();
  return (
    <Header>
      <>
        <Link to={ROUTES.MAIN}>
          <div className="logo">
            <LoginLogo width="170" height="55" />
          </div>
        </Link>
        <div className="header-notification flex-vertical">
          <UserProfile />
          <div className="text-secondary mt-10 user-phone">
            +91{user?.phoneNo}
          </div>
        </div>
      </>
    </Header>
  );
};
export default AppHeader;
