import { useLazyQuery } from '@apollo/client';
import { Col } from 'antd';
import { debounce, get, map, uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useMedia, usePrevious } from 'react-use';
import {
  BREAKPOINTS,
  DROPDOWN_LIMIT,
  SCROLL_CONST
} from '../../../common/constants';
import CommonSelect from '../../../components/CommonSelect';
import { PROJECT_DROPDOWN, TENANT_DROPDOWN } from '../graphql/Queries';

const { Option } = CommonSelect;
let tenantDebounce = null;
let projectDebounce = null;

const Filters = ({
  tenantFilter,
  projectFilter,
  tenantId,
  setTenantId,
  projectId,
  setProjectId,
  selectedProjectRecord = [],
  selectedTenantRecord = [],
  setSelectedProjectRecord,
  setSelectedTenantRecord
}) => {
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [tenantList, setTenantList] = useState([]);
  const [tenantCurrentPage, setTenantCurrentPage] = useState(0);
  const [projectCurrentPage, setProjectCurrentPage] = useState(0);
  const [projectList, setProjectList] = useState([]);
  const [tenantHasMore, setTenantHasMore] = useState(true);
  const [searchTenant, setSearchTenant] = useState('');
  const [projectHasMore, setProjectHasMore] = useState(true);
  const [searchProject, setSearchProject] = useState('');
  const prevId = usePrevious(tenantId);

  const [fetchTenantData, { loading: tenantLoading }] = useLazyQuery(
    TENANT_DROPDOWN,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const tenantData = get(res, 'contactTenantDropdownList.data', []);
        if (tenantCurrentPage === 0) {
          setTenantList(tenantData);
        } else {
          setTenantList((oldData) => uniqBy([...oldData, ...tenantData], 'id'));
        }
        if (tenantId !== prevId) {
          setTenantCurrentPage(0);
          setProjectCurrentPage(0);
        } else {
          setTenantCurrentPage((page) => page + 1);
        }
        setTenantHasMore(tenantData?.length >= DROPDOWN_LIMIT);
      },
      onError() {}
    }
  );
  const [fetchProjectData, { loading: projectLoading }] = useLazyQuery(
    PROJECT_DROPDOWN,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const projectData = get(res, 'contactProjectDropdownList.data', []);
        if (projectCurrentPage === 0) {
          setProjectList(projectData);
        } else {
          setProjectList((oldData) =>
            uniqBy([...oldData, ...projectData], 'id')
          );
        }
        setProjectCurrentPage((page) => page + 1);

        setProjectHasMore(projectData?.length >= DROPDOWN_LIMIT);
      },
      onError() {}
    }
  );

  useEffect(() => {
    if (tenantFilter) {
      fetchTenantData({
        variables: {
          filter: {
            skip: tenantCurrentPage * DROPDOWN_LIMIT,
            limit: DROPDOWN_LIMIT
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (projectFilter) {
      fetchProjectData({
        variables: {
          filter: {
            skip: projectCurrentPage * DROPDOWN_LIMIT,
            limit: DROPDOWN_LIMIT,
            tenantId: tenantId
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantId]);

  const handleTenantScroll = (event) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};
    const scrolledToBottom =
      scrollTop + offsetHeight >= scrollHeight - SCROLL_CONST;
    if (scrolledToBottom && tenantHasMore && !tenantLoading) {
      const obj = {
        variables: {
          filter: {
            skip: tenantCurrentPage * DROPDOWN_LIMIT,
            limit: DROPDOWN_LIMIT,
            search: searchTenant
          }
        }
      };
      fetchTenantData(obj);
    }
  };
  const handleProjectScroll = (event) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};
    const scrolledToBottom =
      scrollTop + offsetHeight >= scrollHeight - SCROLL_CONST;
    if (scrolledToBottom && projectHasMore && !projectLoading) {
      const obj = {
        variables: {
          filter: {
            skip: projectCurrentPage * DROPDOWN_LIMIT,
            limit: DROPDOWN_LIMIT,
            search: searchProject,
            tenantId: tenantId
          }
        }
      };
      fetchProjectData(obj);
    }
  };
  const tenantSearchQuery = (search) => {
    setSearchTenant(search);
    setTenantCurrentPage(0);
    fetchTenantData({
      fetchPolicy: 'network-only',
      variables: {
        filter: {
          search,
          skip: 0,
          limit: DROPDOWN_LIMIT
        }
      }
    });
  };
  const handleTenantSearch = (value) => {
    if (tenantDebounce) {
      tenantDebounce.cancel();
      tenantDebounce = null;
    }
    tenantDebounce = debounce(tenantSearchQuery, 500);
    tenantDebounce(value);
  };
  const handleTenantClear = () => {
    if (searchTenant) {
      tenantSearchQuery();
    }
  };
  const projectSearchQuery = (search) => {
    setSearchProject(search);
    setProjectCurrentPage(0);
    fetchProjectData({
      fetchPolicy: 'network-only',
      variables: {
        filter: {
          search,
          skip: 0,
          limit: DROPDOWN_LIMIT
        }
      }
    });
  };
  const handleProjectSearch = (value) => {
    if (projectDebounce) {
      projectDebounce.cancel();
      projectDebounce = null;
    }
    projectDebounce = debounce(projectSearchQuery, 500);
    projectDebounce(value);
  };
  const handleProjectClear = () => {
    if (searchProject) {
      projectSearchQuery();
    }
  };
  const handleTenantChange = (value, record) => {
    if (record) {
      setSelectedTenantRecord([
        {
          organizationName: record?.children,
          id: record?.value
        }
      ]);
    } else {
      setSelectedTenantRecord([]);
    }
    setTenantId(value);
    setSelectedProjectRecord();
    setProjectCurrentPage(0);
    setProjectId();
  };
  return (
    <>
      {tenantFilter && (
        <Col span={!isDesktopViewport && 12}>
          <CommonSelect
            placeholder="Organization"
            className={isDesktopViewport ? 'width-200' : 'width-percent-100'}
            value={tenantId}
            onChange={handleTenantChange}
            showSearch
            optionFilterProp="children"
            allowClear
            onPopupScroll={handleTenantScroll}
            onSearch={handleTenantSearch}
            onClear={handleTenantClear}
            onBlur={handleTenantClear}
          >
            {map(
              uniqBy([...selectedTenantRecord, ...tenantList], 'id'),
              (item) => (
                <Option key={`tenant-${item?.id}`} value={item?.id}>
                  {item?.organizationName}
                </Option>
              )
            )}
          </CommonSelect>
        </Col>
      )}
      {projectFilter && (
        <Col span={!isDesktopViewport && (tenantFilter ? 12 : 24)}>
          <CommonSelect
            placeholder="Project"
            className={isDesktopViewport ? 'width-200' : 'width-percent-100'}
            value={projectId}
            onChange={(value, record) => {
              setProjectId(value);
              if (record) {
                setSelectedProjectRecord([
                  {
                    name: record?.children,
                    id: record?.value
                  }
                ]);
              } else {
                setSelectedProjectRecord([]);
              }
            }}
            showSearch
            optionFilterProp="children"
            allowClear
            onPopupScroll={handleProjectScroll}
            onSearch={handleProjectSearch}
            onClear={handleProjectClear}
            onBlur={handleProjectClear}
          >
            {map(
              uniqBy([...selectedProjectRecord, ...projectList], 'id'),
              (item) => (
                <Option key={`project-${item?.id}`} value={item?.id}>
                  {item?.name}
                </Option>
              )
            )}
          </CommonSelect>
        </Col>
      )}
    </>
  );
};

export default Filters;
