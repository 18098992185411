import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_AGENCY_EQC_APPROVAL_LOGS = gql`
  query getAgencyEqcApprovalLogs($filter: EqcApprovalLogInput!) {
    getAgencyEqcApprovalLogs(filter: $filter) {
      total
      data {
        id
        updatedAt
        approvalStatus
        tenant {
          id
          organizationName
        }
        approvalDetails
        eqc {
          id
          eqcName
          projectEqcTypeName
          project {
            id
            name
          }
        }
        eqcStageHistory {
          id
          name
          status
          eqcStageId
        }
        approvalSender {
          id
          name
          email
        }
        internalApprover {
          id
          project {
            id
            name
          }
          user {
            id
            name
          }
        }
        eqcStage {
          id
          name
          status
          eqcStageApprovals {
            id
            internalApproverId
            externalApproverId
            internalApproverName
            externalApproverName
          }
        }
        approvalUrl
      }
    }
  }
`;

export const GET_AGENCY_EQC_APPROVAL_LOGS_COUNT = gql`
  query getAgencyEqcApprovalLogs($filter: EqcApprovalLogInput!) {
    getAgencyEqcApprovalLogs(filter: $filter) {
      total
    }
  }
`;

export const GET_URLS = gql`
  query getSignedPutUrls($data: [SignedUrlDataInput]) {
    getSignedPutUrls(data: $data) {
      keys
      signedUrls
    }
  }
`;

export const GET_DETAILS = gql`
  query getInstructionForContact($id: ID!) {
    getInstructionForContact(id: $id) {
      id
      name
      description
      closingAudio
      closingRemark
      closingPhoto
      tenantId
      createdOn
      type
      status
      respondedOn
      closedOn
      reminderDate
      uuid
      projectId
      closer {
        id
        name
      }
      responder {
        id
        name
      }
      projectAgency {
        id
        agency {
          id
          name
        }
      }
      tenant {
        id
        organizationName
      }
      creator {
        id
        name
      }
      description
      descriptionAudio
      descriptionPhoto
      instructionIssueHistories {
        id
        status
        photos
        audios
        projectId
        remark
        revision
        projectAgencyId
        createdAt
        rejector {
          id
          name
        }
        responder {
          id
          name
        }
        creator {
          id
          name
          email
        }
      }
      instructionHistory {
        id
        modifier {
          id
          name
        }
        createdAt
        afterJson
        beforeJson
      }
    }
  }
`;

export const GET_INSTRUCTION_LIST = gql`
  query contactInstructionList($filter: InstructionFilter!) {
    contactInstructionList(filter: $filter) {
      total
      data {
        id
        name
        type
        status
        reminderDate
        createdAt
        isOverdue
        respondedOn
        updatedAt
        project {
          id
          name
        }
        responder {
          id
          name
        }
        modifier {
          id
          name
        }
        rejector {
          id
          name
        }
        tenant {
          id
          organizationName
        }
      }
    }
  }
`;

export const TENANT_DROPDOWN = gql`
  query contactTenantDropdownList($filter: ContactFilter) {
    contactTenantDropdownList(filter: $filter) {
      total
      data {
        id
        organizationName
      }
    }
  }
`;
export const PROJECT_DROPDOWN = gql`
  query contactProjectDropdownList($filter: ContactFilter!) {
    contactProjectDropdownList(filter: $filter) {
      total
      data {
        id
        name
      }
    }
  }
`;
export const TYPE_DROPDOWN = gql`
  query instructionTypeList {
    instructionTypeList
  }
`;
